import React from 'react'
import { Fade } from 'react-slideshow-image'
import { graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Slide from '../components/Slide'
import CoveredText from '../components/CoveredText'
import Helmet from 'react-helmet'

const IndexPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { toppageTitle, description },
    },
    allMarkdownRemark: { edges },
  } = data

  return (
    <>
      <Helmet>
        <html className="index" lang="ja" />
        <title>{toppageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Layout>
        <div id="slide">
          <Fade autoplay indicators arrows={false}>
            <Slide>
              <CoveredText>
                <p className="header-title">
                  ロック板のない<p className="sp-only"></p>はじめての駐車場
                </p>
              </CoveredText>
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/toppage-slider-01.webp"
                />
                <img
                  className="hero-image"
                  src={`/assets/images/common/toppage-slider-01.jpg`}
                  alt=""
                />
              </picture>
            </Slide>
            <Slide>
              <CoveredText>
                <p className="header-title">
                  インターネット接続に対応した<p className="sp-only"></p>
                  新世代の精算機
                </p>
              </CoveredText>
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/pc/toppage-slider-02.webp"
                />
                <img
                  className="hero-image pc-only"
                  src={`/assets/images/pc/toppage-slider-02.jpg`}
                  alt=""
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/sp/toppage-slider-02.webp"
                />
                <img
                  className="hero-image sp-only"
                  src={`/assets/images/sp/toppage-slider-02.jpg`}
                  alt=""
                />
              </picture>
            </Slide>
            <Slide>
              <CoveredText>
                <p className="header-title">
                  いつでもどこでも<p className="sp-only"></p>安心して外出できる
                  <br />
                  モビリティ社会を目指します
                  <p className="sp-only"></p>
                </p>
              </CoveredText>
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/toppage-slider-03.webp"
                />
                <img
                  className="hero-image"
                  src={`/assets/images/common/toppage-slider-03.jpg`}
                  alt=""
                />
              </picture>
            </Slide>
          </Fade>
        </div>

        <div className="topix-area clearfix">
          <div className="date">{edges[0].node.frontmatter.date}</div>
          <div className="title">
            <Link to={edges[0].node.fields.slug}>
              {edges[0].node.frontmatter.title}
            </Link>
          </div>
          <div className="common-btn">
            <Link to={`/news`}>トピックス一覧</Link>
          </div>
        </div>

        <section className="about">
          <div className="row top">
            <div className="wrapper">
              <div className="blanc-box"></div>
            </div>
          </div>
          <div className="row title">
            <div className="blanc"></div>
            <div className="wrapper">
              <div className="slogan">
                お客様に喜ばれる駐車場を、
                <br />
                一緒に作りましょう
              </div>
            </div>
          </div>

          <div className="row main">
            <div className="container clearfix">
              <div className="border"></div>
              <div className="left-contents">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/toppage-about.webp"
                  />
                  <img
                    className="pc-only"
                    src={`/assets/images/common/toppage-about.jpg`}
                    alt=""
                  />
                </picture>
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/sp/toppage-about.webp"
                  />
                  <img
                    className="sp-only"
                    src={`/assets/images/sp/toppage-about.jpg`}
                    alt=""
                    height="173"
                  />
                </picture>
                <div className="shadow"></div>
              </div>

              <div className="right-contents">
                <p>
                  私たちは、車の出し入れ時にロック板に乗り上げなければならないという利用者のストレスを減らすことを目指し、ナンバー認証システムによるロックレス駐車場を開発いたしました。
                  <br />
                  <br />
                  ロックレス駐車場は、不正出庫を減らすことにも寄与し、駐車場運営者の利益を守りながら、利用者にもメリットがある。最も理想的な駐車場システムだと自負しています。
                </p>
                <div className="common-link">
                  <AnchorLink to="/mvv/" title="ミッション・ビジョン・バリュー">
                    ミッション・ビジョン・バリュー
                  </AnchorLink>
                </div>
                <div className="common-link">
                  <Link to={`/service/lockless/`}>
                    ロックレス駐車場について(製品・サービス紹介)
                  </Link>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </section>

        <section className="service">
          <div className="row">
            <div className="h-wrapper">
              <h2>製品・サービス</h2>
              <div className="border left"></div>
              <div className="border center"></div>
              <div className="border right"></div>
            </div>
          </div>

          <div className="row pannel pc-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/internet/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-netdeal.webp"
                    />
                    <img src="/assets/images/common/icon-netdeal.png" alt="" />
                  </picture>
                  ネットワーク対応精算機
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/remote/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-remotecontrol.webp"
                    />
                    <img
                      src="/assets/images/common/icon-remotecontrol.png"
                      alt=""
                    />
                  </picture>
                  遠隔管理システム
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/lockless/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-lockless.webp"
                    />
                    <img src="/assets/images/common/icon-lockless.png" alt="" />
                  </picture>
                  ナンバー認識システム「ロックレス」
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel pc-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/gate/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-gatesystem.webp"
                    />
                    <img
                      src="/assets/images/common/icon-gatesystem.png"
                      alt=""
                    />
                  </picture>
                  ゲート式駐車場管理システム
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/unit/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-lockunit.webp"
                    />
                    <img src="/assets/images/common/icon-lockunit.png" alt="" />
                  </picture>
                  ロック板ユニット
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/bike/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-bikelock.webp"
                    />
                    <img src="/assets/images/common/icon-bikelock.png" alt="" />
                  </picture>
                  バイク用ロック板ユニット
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel pc-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/bicycle/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-bicycle.webp"
                    />
                    <img src="/assets/images/common/icon-bicycle.png" alt="" />
                  </picture>
                  駐輪管理システム
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/camera/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-surveillancecamera.webp"
                    />
                    <img
                      src="/assets/images/common/icon-surveillancecamera.png"
                      alt=""
                    />
                  </picture>
                  防犯カメラ
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/qtnetservice/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-qtnet.webp"
                    />
                    <img src="/assets/images/common/icon-qtnet.png" alt="" />
                  </picture>
                  QT-net サービス
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel pc-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/qtnet/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-qtnet.webp"
                    />
                    <img src="/assets/images/common/icon-qtnet.png" alt="" />
                  </picture>
                  QT-net
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/smartpay/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-smartpay.webp"
                    />
                    <img src="/assets/images/common/icon-smartpay.png" alt="" />
                  </picture>
                  SmartPay
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/support/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-support.webp"
                    />
                    <img src="/assets/images/common/icon-support.png" alt="" />
                  </picture>
                  管理サポート
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel pc-only">
            <div className="container">
              <div className="inner">
                <a
                  href="/assets/files/itc-product-maintenance.pdf"
                  target="_blank"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-setting.webp"
                    />
                    <img src="/assets/images/common/icon-setting.png" alt="" />
                  </picture>
                  生産終了及び保守期限一覧表
                </a>
              </div>
              <div className="inner blanc"></div>
              <div className="inner blanc"></div>
            </div>
          </div>

          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner multi">
                <Link to={`/service/internet/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-netdeal.webp"
                    />
                    <img src="/assets/images/common/icon-netdeal.png" alt="" />
                  </picture>
                  ネットワーク
                  <br />
                  対応精算機
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/remote/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-remotecontrol.webp"
                    />
                    <img
                      src="/assets/images/common/icon-remotecontrol.png"
                      alt=""
                    />
                  </picture>
                  遠隔管理システム
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner multi">
                <Link to={`/service/lockless/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-lockless.webp"
                    />
                    <img src="/assets/images/common/icon-lockless.png" alt="" />
                  </picture>
                  ナンバー認識システム
                  <br />
                  「ロックレス」
                </Link>
              </div>{' '}
              <div className="inner multi">
                <Link to={`/service/gate/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-gatesystem.webp"
                    />
                    <img
                      src="/assets/images/common/icon-gatesystem.png"
                      alt=""
                    />
                  </picture>
                  ゲート式駐車場
                  <br />
                  管理システム
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/unit/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-lockunit.webp"
                    />
                    <img src="/assets/images/common/icon-lockunit.png" alt="" />
                  </picture>
                  ロック板ユニット
                </Link>
              </div>
              <div className="inner multi">
                <Link to={`/service/bike/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-bikelock.webp"
                    />
                    <img src="/assets/images/common/icon-bikelock.png" alt="" />
                  </picture>
                  バイク用
                  <br />
                  ロック板ユニット
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/camera/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-surveillancecamera.webp"
                    />
                    <img
                      src="/assets/images/common/icon-surveillancecamera.png"
                      alt=""
                    />
                  </picture>
                  防犯カメラ
                </Link>
              </div>
              <div className="inner">
                <a href="/service/bicycle/">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-bicycle.webp"
                    />
                    <img src="/assets/images/common/icon-bicycle.png" alt="" />
                  </picture>
                  駐輪管理システム
                </a>
              </div>
            </div>
          </div>
          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/qtnetservice/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-qtnet.webp"
                    />
                    <img src="/assets/images/common/icon-qtnet.png" alt="" />
                  </picture>
                  QT-net サービス
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/qtnet/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-qtnet.webp"
                    />
                    <img src="/assets/images/common/icon-qtnet.png" alt="" />
                  </picture>
                  QT-net
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner">
                <Link to={`/service/smartpay/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-smartpay.webp"
                    />
                    <img src="/assets/images/common/icon-smartpay.png" alt="" />
                  </picture>
                  SmartPay
                </Link>
              </div>
              <div className="inner">
                <Link to={`/service/support/`}>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-support.webp"
                    />
                    <img src="/assets/images/common/icon-support.png" alt="" />
                  </picture>
                  管理サポート
                </Link>
              </div>
            </div>
          </div>
          <div className="row pannel sp-only">
            <div className="container">
              <div className="inner">
                <a
                  href="/assets/files/itc-product-maintenance.pdf"
                  target="_blank"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/icon-setting.webp"
                    />
                    <img src="/assets/images/common/icon-setting.png" alt="" />
                  </picture>
                  生産終了及び保守期限一覧表
                </a>
              </div>
              <div className="inner blanc"></div>
            </div>
          </div>

          <div className="row">
            <div className="container contact">
              <div className="border top-left"></div>
              <div className="border top-right"></div>

              <div className="box left">
                <Link to={`/whitepaper`}>
                  <p className="title">製品カタログ ダウンロード</p>
                  <p className="detail">
                    製品・ソリューション、弊社の事例など、
                    <br />
                    お気軽にお問い合わせください。
                  </p>
                  <div className="link-box">ダウンロードページはこちら</div>
                  <div className="shadow sp-only"></div>
                  <div className="blanc sp-only"></div>
                </Link>
              </div>

              <div className="box right">
                <Link to={`/contact/`}>
                  <p className="title">お問い合わせ</p>
                  <p className="detail">
                    お電話、またはお問い合わせフォームより
                    <br />
                    お問い合わせや、ご注文が可能です。
                  </p>
                  <div className="link-box">お問い合わせフォームはこちら</div>
                  <div className="shadow sp-only"></div>
                  <div className="blanc sp-only"></div>
                </Link>
              </div>

              <div className="shadow pc-only"></div>
              <div className="blanc pc-only"></div>
              <div className="border bottom"></div>
            </div>
          </div>
        </section>

        <section className="topics">
          <div className="row">
            <div className="h-wrapper">
              <h2>トピックス</h2>
              <div className="common-btn">
                <Link to={`/news/`}>トピックス一覧</Link>
              </div>
              <div className="border"></div>
            </div>
          </div>
          <div className="row nopadding">
            <div className="topics-wrapper clearfix">
              <div className="left-container">
                {edges.map((aNews) => (
                  <div key={aNews.node.id} className="topic">
                    <div className="wrapper">
                      <p className="date">{aNews.node.frontmatter.date}</p>
                      <p className="title">
                        <Link to={aNews.node.fields.slug}>
                          {aNews.node.frontmatter.title}
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}

                <div className="topic">
                  <div className="wrapper">
                    <div className="link-box">
                      <Link to={`/news/`}>過去のトピックスを見る</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-container">
                <div className="wrapper">
                  <div className="box comic">
                    <Link to={`/lp/`} className="pc-only">
                      <p>
                        漫画でわかる
                        <br />
                        お客様に喜ばれる
                        <br />
                        快適駐車場の作り方
                      </p>
                      <div className="read">漫画を読む</div>
                    </Link>
                    <p className="sp-only">
                      漫画でわかる
                      <br />
                      お客様に喜ばれる
                      <br />
                      快適駐車場の作り方
                    </p>
                    <div className="link-box sp-only">
                      <Link to={`/lp/`} className="pc-only">
                        漫画を読む
                      </Link>
                    </div>
                    <div className="shadow"></div>
                  </div>
                  <div className="box">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/common/icon-faq.webp"
                      />
                      <img src="/assets/images/common/icon-faq.png" alt="" />
                    </picture>
                    <p>よくある質問</p>
                    <div className="link-box">
                      <Link to={`/faq/`}>詳しく見る</Link>
                    </div>
                    <div className="shadow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="qt-net">
          <div className="row">
            <div className="h-wrapper">
              <h2>QT-net会員サービス</h2>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/pc/about-qtnet.webp"
                />
                <img
                  className="pc-only"
                  src="/assets/images/pc/about-qtnet.jpg"
                  alt="QT-net会員サービス"
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/sp/about-qtnet.webp"
                />
                <img
                  className="sp-only"
                  src="/assets/images/sp/about-qtnet.jpg"
                  width="500"
                  height="274"
                  alt="QT-net会員サービス"
                />
              </picture>
              <div className="shadow"></div>
              <div className="right-contents">
                <p>
                  アイテックが管理運営する「QT-net」は、スマートフォンで精算が出来るサービス「SmartPay」を提供しています。
                  <br />
                  <br />
                  QT駐車場ツールアプリをダウンロードし、会員登録とクレジットカード情報を登録しておけば、運転席からご利用料金の精算が出来ます。キャッシュレス＆ポイント還元がお客様に支持され、利用可能なQT-net加盟駐車場が、全国に拡大を続けています。
                </p>
                <div className="common-link">
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    iOSアプリのダウンロードはこちら
                  </a>
                </div>
                <div className="common-link">
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Androidアプリのダウンロードはこちら
                  </a>
                </div>
              </div>
              <div className="clear"></div>
              <div className="wrapper">
                <div className="link-box">
                  <Link to={`/service/qtnetservice/`}>QT-net</Link>
                </div>
                <div className="link-box">
                  <Link to={`/service/qtnet/`}>QT-netの利用方法</Link>
                </div>
              </div>
              <div className="common-link">
                <Link to={`/service/smartpay/`}>SmartPayの使い方はこちら</Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query NewsIndexQuery {
    site {
      siteMetadata {
        toppageTitle
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news-template" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
      skip: 0
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  }
`
